import React, { useContext } from "react";
import Container from "@src/components/Container";
import styled from "@emotion/styled";
import { Button, Space, theme, Title } from "@src/components/nessie-web";
import Translate from "@src/components/translation/Translate";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import { AppDataContext } from "@src/components/AppDataContext";
import { mediaQueries } from "@src/styles/theme";
import loadingMojo from "@src/assets/images/loadingMojo.gif";

const {
  colors: { dt_taro90, dt_white },
  radii: { dt_radius_m },
} = theme;

const NotLoggedSection = styled.section`
  padding-top: 54px;
  background-color: ${dt_taro90};
  min-height: 420px;

  ${mediaQueries[0]} {
    padding-top: 108px;
  }
`;

const NotLoggedContent = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 30px;
  border-radius: ${dt_radius_m};
  background-color: ${dt_white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const IframeContainer = styled.section`
  width: 100%;
  max-width: 750px;
  border: 1px solid ${dt_taro90};
  margin: auto;
`;

const DataSubjectRightsRequest: React.FC = () => {
  const modalContext = useContext(ModalContext);
  const openLoginModal = () => {
    modalContext.showModal(ModalType.Login, { noRedirect: true });
  };

  const appData = useContext(AppDataContext);

  if (appData.data.loadingSession) {
    return (
      <NotLoggedSection>
        <Container>
          <NotLoggedContent>
            <img src={loadingMojo} alt="Loading mojo" />
          </NotLoggedContent>
        </Container>
      </NotLoggedSection>
    );
  } else {
    if (!appData.data.type) {
      return (
        <NotLoggedSection>
          <Container>
            <NotLoggedContent>
              <Title size={1}>
                <Translate path="directus.page_data_subject_rights.page_text" />
              </Title>
              <Space size="l" />
              <Button onClick={openLoginModal}>
                <Translate path="directus.page_data_subject_rights.button_text" />
              </Button>
            </NotLoggedContent>
          </Container>
        </NotLoggedSection>
      );
    }

    return (
      <IframeContainer>
        <iframe
          title="classdojo-webform"
          src="https://classdojo-requests.my.onetrust.com/webform/4d69cd7d-9470-4b2f-9269-1c4ac8e640b9/7de643a4-3064-48bd-a361-c3fc4a70375f"
          style={{ width: "100%", height: "2500px", border: "none" }}
          scrolling="no"
        ></iframe>
      </IframeContainer>
    );
  }
};

export default DataSubjectRightsRequest;
